import React from "react";
import { Container } from "react-bootstrap";

import EveryCard from "../global/every_card";
import EveryHero from "../global/every_hero";
import PageLead from "../global/page_lead";
import CallToAction from "../global/call_to_action";

import "./services.scss";

import imgServicesHero from "../../assets/images/imgVetFamily2.webp";
import imgWebsiteClient from "../../assets/images/imgWebDog2.jpg";
import imgGoogleWorkspace from "../../assets/images/imgGoogleWorkspace2.jpg";

const Services = (props) => {
    let { showSnack } = props;
    return (
        <>
            <EveryHero image={imgServicesHero} title="Services" top="20" />
            <PageLead
                text={<>We're determined to deliver great experiences and create websites that people <span className="page-lead-text-script">love</span> to share.</>}
                subtext={<>We have years of experience creating and deploying software for businesses around the world. Let us help you share your passion with your community.</>}
            />
            <Container fluid className="services-section-container-gray">
                <Container>
                    <div className="services-section-inner-container">
                        <EveryCard 
                            image={imgWebsiteClient} 
                            title={<div>Websites That Just Work</div>} 
                            titleSeparator={true}
                            bodyText={
                                <>
                                    <p>
                                        Our website designs will look great on any phone or desktop browser. We create secure, custom sites 
                                        that load fast and fit your needs exactly. Our sites incorporate Google Analytics, which will provide reports 
                                        about monthly site traffic and performance. You'll see how people are searching and finding your site and we'll 
                                        help optimize your content for the best search results.
                                    </p>
                                    <p>
                                        Most importantly, we're here for you. We know your business moves quickly and changes often. When you need an 
                                        update to your website, we're on it - often providing same day service for things like staff and forms updates.
                                    </p>
                                </>
                            }
                            buttonText=""
                            buttonURL=""
                            focus="text"
                            verticalOrientation="top"
                            horizontalOrientation="right"
                        />
                    </div>
                </Container>
            </Container>
            <Container fluid>
                <Container>
                    <div className="services-section-inner-container">
                        <EveryCard 
                            image={imgGoogleWorkspace} 
                            title={<div>Google Email, Docs and Sheets</div>} 
                            titleSeparator={true}
                            bodyText={
                                <>
                                    <p>
                                        If you have a new clinic or just need to take control of your office productivity, we can help! Google Workspace provides small businesses 
                                        an affordable, easy-to-use, familiar set of tools to run your practice. We can set up your office email, shared 
                                        documents and spreadsheets, and even chat. We can train someone on your staff to be 
                                        an administrator, or we can even manage it for you remotely.
                                    </p>
                                    <p>
                                        With Google Workspace you can have Gmail addresses customized to your domain (for example:&nbsp;  
                                        <strong>john.doe@myvetpractice.com</strong>). And the best part is their plans start at only $6 per person, 
                                        per month. So you can afford to allow everyone on your staff a personal email address.
                                    </p>
                                </>
                            }
                            buttonText=""
                            buttonURL=""
                            focus="text"
                            verticalOrientation="top"
                            horizontalOrientation="left"
                        />
                    </div>
                </Container>
            </Container>
            <CallToAction showSnack={showSnack} />
        </>
    );
};

export default Services;