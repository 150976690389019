import * as yup from "yup";
import regexPatterns from "../enum/regex_patterns";

const contactUsForm = yup
    .object()
    .shape({
        full_name: yup.string()
            .required("Please enter your full name."),
        email: yup.string()
            .required("Please enter a valid email address.")
            .matches(regexPatterns.EMAIL, "Please enter a valid email address.")
    });

const leadMagnetForm = yup
    .object()
    .shape({
        first_name: yup.string()
            .required("Please enter your first name."),
        last_name: yup.string()
            .required("Please enter your last name."),
        email: yup.string()
            .required("Please enter a valid email address.")
            .matches(regexPatterns.EMAIL, "Please enter a valid email address.")
    });

const FormsSchema = { 
    contactUsForm,
    leadMagnetForm
};

export default FormsSchema;