import React from "react";
import { Container } from "react-bootstrap";

import "./scss/page_lead.scss";

const PageLead = (props) => {
    let { text, subtext = null } = props;
    return (
        <Container>
            <h2 className="page-lead">
                {text}
            </h2>
            { subtext &&
                <div className="page-lead-subtext">
                    {subtext}
                </div> 
            }
            <div className="page-lead-separator">&nbsp;</div>
        </Container>
    );
};

export default PageLead;