import { Container } from "react-bootstrap";

import EveryCard from "../global/every_card";

import "./scss/main_plan.scss";

import imgMainVetPhone from "../../assets/images/imgVetPhone.webp";

const MainPlan = () => {
    return (
        <Container fluid className="main-plan-container">
            <Container>
                <div className="main-plan-inner-container">
                    <EveryCard 
                        image={imgMainVetPhone} 
                        title={<div>Here's How <span className="every-card-text-script">It Works</span></div>} 
                        titleSeparator={true}
                        bodyText={
                            <ol>
                                <li><strong>Schedule a call</strong> - we'd love to hear your ideas and share how EveryDVM can help your business. When you're ready, we'll get started!</li>
                                <li><strong>Gather and build</strong> - you'll work with a designer to gather the text, photos, and other materials we'll need to design your site. We'll share the progress every step of the way as we build.</li>
                                <li><strong>Launch!</strong> - once you've given us final approval, we'll launch your site for the world to see.</li>
                            </ol>}
                        buttonText="GET STARTED TODAY"
                        buttonURL="/contact-us"
                        verticalOrientation="top"
                        horizontalOrientation="left"
                    />
                </div>
            </Container>
        </Container>
    );
};

export default MainPlan;