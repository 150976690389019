import React from "react";
import { Button, Container } from "react-bootstrap";

//import imageMainHero from "../../assets/images/imageMainHero01.webp";
import imageClient from "../../assets/images/imgMainClient.webp";

import "./scss/main_hero.scss";

const MainHero = () => {
    return (
        <Container className="main-hero-container">
            <div className="main-hero-inner-container max-width-xl">
                <div className="main-hero-identity-container">
                    <h1>Get a website for your veterinary practice that makes you <span className="display-6 badscript">proud</span></h1>
                    <div className="main-hero-identity-tag">
                        We custom-build modern, professional websites for veterinary practices that load fast and work beautifully on mobile. Let us create a fresh new look for your business!
                    </div>
                    <Button variant="primary" href="/contact-us" className="call-to-action-button main-hero-call-to-action-button">GET STARTED TODAY</Button>
                </div>
                <div className="main-hero-image-container">
                    <img src={imageClient} alt="" />
                </div>
            </div>
        </Container>
    );
};

export default MainHero;