import React from "react";
import { Button } from "react-bootstrap";

import "./scss/every_card.scss";

/**
 * EveryCard is a responsive custom card implementation that will adapt to the
 * container it's placed in. The card defaults to a vertical (over/under) format 
 * from size "xs" to "md" (up to 991px wide). From size "lg" and up (992px wide 
 * and greater) the format changes to a row (side-by-side) format.
 * 
 */
const EveryCard = (props) => {
    let { 
        image, 
        title, 
        titleSeparator,
        bodyText, 
        buttonText = "",
        buttonURL = "",
        focus="image",
        verticalOrientation = "top",
        horizontalOrientation = "left" } = props;

    // if neither top or bottom, default to top (I do this to correct invalid passed params).
    if (verticalOrientation !== "top" && verticalOrientation !== "bottom") {
        verticalOrientation = "top";
    }

    // if neither left or right, default to left (I do this to correct invalid passed params).
    if (horizontalOrientation !== "left" && horizontalOrientation !== "right") {
        horizontalOrientation = "left";
    }

    let verticalCSS = "";
    if (verticalOrientation === "bottom") {
        verticalCSS = "bottom";
    }

    let horizontalCSS = "";
    if (horizontalOrientation === "right") {
        horizontalCSS = "right";
    }

    let focusCSS = "focusImage";
    if (focus === "text") {
        focusCSS = "focusText";
    }

    return (
        <div className={`every-card-container ${verticalCSS} ${horizontalCSS}`}>
            <div className={`every-card-image-container ${focusCSS}`} style={{backgroundImage: `url(${image})`}}>
                &nbsp;
            </div>
            <div className={`every-card-body-container ${focusCSS}`}>
                <div className="every-card-body-top">
                    <div className="h2 every-card-title">
                        {title}
                    </div>
                    { titleSeparator && <div className="every-card-title-separator">&nbsp;</div> }
                    <div className="every-card-text-container">
                        {bodyText}
                    </div>
                </div>
                { buttonText && 
                    <div className="every-card-button-container">
                        <Button variant="primary" href={buttonURL} className="btn-sizing">{buttonText}</Button>
                    </div>
                }
            </div>
        </div>
    );
};

export default EveryCard;