// Send message via EMailJS > SendGrid
import emailjs from "@emailjs/browser";
import templateType from "../enum/email_template_type";

const useMessageService = () => {

    const determineDynamicData = async (messageData) => {

        let noDynamicData = { no_data : null};

        let websiteContactUsData = {
            recipient_name : process.env.REACT_APP_EMAILJS_TO_NAME,
            recipient_email : process.env.REACT_APP_EMAILJS_TO_EMAIL,
            recipient_org_name : process.env.REACT_APP_EMAILJS_TO_ORG_NAME,
            visitor_name : messageData.visitorName,
            visitor_email : messageData.visitorEmail,
            visitor_phone : messageData.visitorPhone,
            visitor_message : messageData.visitorMessage,
            "g-recaptcha-response" : messageData.recaptchaToken
        };

        let websiteLeadMagnetData = {
            recipient_name : `${messageData.visitorFirstName} ${messageData.visitorLastName}`,
            recipient_email : messageData.visitorEmail,
            brochure_title : messageData.brochureTitle,
            brochure_filename : `${process.env.REACT_APP_SITE_URL}/downloads/${messageData.brochureFileName}`,
            "g-recaptcha-response" : messageData.recaptchaToken
        };

        let websiteLeadMagnetCaptureData = {
            recipient_name : process.env.REACT_APP_EMAILJS_TO_NAME,
            recipient_email : process.env.REACT_APP_EMAILJS_TO_EMAIL,
            recipient_org_name : process.env.REACT_APP_EMAILJS_TO_ORG_NAME,
            visitor_name : messageData.visitorName,
            visitor_email : messageData.visitorEmail,
            brochure_title : messageData.brochureTitle,
            site_name : process.env.REACT_APP_SITE_NAME
        };
            
        let dynamicTemplateData = noDynamicData;

        if (messageData.templateName === templateType.CONTACT_US) { 
            dynamicTemplateData = websiteContactUsData;
        }
        if (messageData.templateName === templateType.LEAD_MAGNET) { 
            dynamicTemplateData = websiteLeadMagnetData;
        }
        if (messageData.templateName === templateType.LEAD_MAGNET_CAPTURE) { 
            dynamicTemplateData = websiteLeadMagnetCaptureData;
        }

        return dynamicTemplateData;

    };

    const sendMessage = async (data) => {
        try {
            let messageData = data;
            let userId = process.env.REACT_APP_EMAILJS_USER_ID;
            let serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;

            let templateId = "";
            switch (messageData.templateName) {
                case templateType.CONTACT_US:
                    templateId = process.env.REACT_APP_EMAILJS_CONTACT_US_TEMPLATE_ID;
                    break;
                case templateType.LEAD_MAGNET:
                    templateId = process.env.REACT_APP_EMAILJS_LEAD_MAGNET_TEMPLATE_ID;
                    break;
                case templateType.LEAD_MAGNET_CAPTURE:
                    templateId = process.env.REACT_APP_EMAILJS_LEAD_MAGNET_CAPTURE_TEMPLATE_ID;
                    break;
                default:
                    templateId = process.env.REACT_APP_EMAILJS_CONTACT_US_TEMPLATE_ID;
            }

            let dynamicTemplateData = await determineDynamicData(messageData);

            let res = await emailjs.send(
                serviceId, 
                templateId, 
                dynamicTemplateData, 
                userId
            );

            return res;

        } catch (e) {
            return (e.message);
        }

    };

    return {
        sendMessage
    };
};

export default useMessageService;