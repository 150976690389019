import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import snackbarType from "../../enum/snackbar_type";
import templateType from "../../enum/email_template_type";
import FormsSchema from "../../schema/forms";

import useMessageService from "../../service/message_service";

import "./scss/call_to_action.scss";

import imgEveryDVMBrochure from "../../assets/images/imgEveryDVMBrochure.webp";

const CallToAction = (props) => {
    let { showSnack } = props;
    const recaptchaRef = useRef();
    const messageService = useMessageService();

    const { leadMagnetForm } = FormsSchema;

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(leadMagnetForm)
    });

    let [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    let [recaptchaToken, setRecaptchaToken] = useState("");

    const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const handleToken = (token) => {
        setRecaptchaToken(token);
        setSubmitIsDisabled(false);
    };

    const handleTokenExpired = async () => {
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    const onSubmit = async (data) => {

        let messageData = {
            visitorFirstName: data.first_name,
            visitorLastName: data.last_name,
            visitorEmail: data.email,
            brochureTitle: "6 Steps to Creating the Right Website For Your Veterinary Practice",
            brochureFileName: process.env.REACT_APP_6_STEP_WEBSITE_FILENAME,
            templateName: templateType.LEAD_MAGNET,
            recaptchaToken
        };
            
        let res = await messageService.sendMessage(messageData);
        if (res && res.status === 200) {
            showSnack(snackbarType.GOOD_MESSAGE, "An email has been sent with a link to download!");
        }

        // send a 2nd email via Contact Us form so we can capture this info
        let captureData = {
            visitorName: `${data.first_name} ${data.last_name}`,
            visitorEmail: data.email,
            brochureTitle: "6 Steps to Creating the Right Website For Your Veterinary Practice",
            templateName: templateType.LEAD_MAGNET_CAPTURE,
            recaptchaToken
        };

        await messageService.sendMessage(captureData);
        
        setValue("first_name", null);
        setValue("last_name", null);
        setValue("email", null);
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    return (
        <Container fluid className="call-to-action-container">
            <Container>
                <h5 className="call-to-action-headings mb-3">Free Download!</h5>
                <h2 className="call-to-action-headings call-to-action-title">6 Steps to Creating the Right Website For Your Veterinary Practice</h2>
                <div className="call-to-action-inner-container">
                    <div className="call-to-action-form-container">
                        <div className="call-to-action-form">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="call-to-action-short-fields-container">
                                    <Form.Group className="call-to-action-form-input">
                                        <Form.Label htmlFor="first_name">First Name<span className="text-danger"> *</span></Form.Label>
                                        <Form.Control
                                            id="first_name"
                                            type="text"
                                            {...register("first_name")}
                                            className={`${errors.first_name ? "is-invalid" : ""}`}
                                        />
                                        {errors.first_name && <span className="call-to-action-form-input-error text-danger">{errors.first_name.message}</span>}
                                    </Form.Group>
                                    <Form.Group className="call-to-action-form-input">
                                        <Form.Label htmlFor="last_name">Last Name<span className="text-danger"> *</span></Form.Label>
                                        <Form.Control
                                            id="last_name"
                                            type="text"
                                            {...register("last_name")}
                                            className={`${errors.last_name ? "is-invalid" : ""}`}
                                        />
                                        {errors.last_name && <span className="call-to-action-form-input-error text-danger">{errors.last_name.message}</span>}
                                    </Form.Group>
                                </div>
                                <Form.Group className="call-to-action-form-input">
                                    <Form.Label htmlFor="email">Email Address<span className="text-danger"> *</span></Form.Label>
                                    <Form.Control
                                        id="email"
                                        type="text"
                                        {...register("email")}
                                        className={`${errors.email ? "is-invalid" : ""}`}
                                    />
                                    {errors.email && <span className="call-to-action-form-input-error text-danger">{errors.email.message}</span>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        type="hidden"
                                        {...register("recaptchaToken")}
                                        value={recaptchaToken}
                                    />
                                    <div className="mb-3">
                                        <ReCAPTCHA
                                            sitekey={reCaptchaSiteKey}
                                            onChange={handleToken}
                                            onExpired={handleTokenExpired}
                                            ref={recaptchaRef}
                                        />
                                    </div>
                                </Form.Group>
                                <Button variant="primary" type="submit" className="call-to-action-button-lg" disabled={submitIsDisabled}>
                                    DOWNLOAD PDF
                                </Button>
                            </Form>
                        </div>
                    </div>
                    <div className="call-to-action-brochure-container">
                        <img src={imgEveryDVMBrochure} className="call-to-action-brochure" alt="EveryDVM brochure" />
                    </div>
                </div>
            </Container>
        </Container>
    );
};

export default CallToAction;