import React from "react";
import { Container } from "react-bootstrap";

import EveryCard from "../global/every_card";
import EveryHero from "../global/every_hero";
import PageLead from "../global/page_lead";
import CallToAction from "../global/call_to_action";

import "./about.scss";

import imgAboutHero from "../../assets/images/imgDevDog1.jpg";
import imgCaseyMcMullen from "../../assets/images/imgCaseyMcMullen.jpg";
import imgOurClients from "../../assets/images/imgVetFamily1.jpg";

const AboutUs = (props) => {
    let { showSnack } = props;
    return (
        <>
            <EveryHero image={imgAboutHero} title="About Us" top="30" />
            <PageLead
                text={<>We're a small team of developers that own pets and <span className="page-lead-text-script">enjoys</span> building websites for veterinary clinics.</>}
                subtext={<>We're not just co-workers, we're a group of friends that genuinely care about our clients and dedicate ourselves to helping them shine.</>}
            />
            <Container fluid className="about-section-container-gray">
                <Container>
                    <div className="about-section-inner-container">
                        <EveryCard 
                            image={imgOurClients} 
                            title={<div>We Serve Veterinarians</div>} 
                            titleSeparator={true}
                            bodyText={
                                <>
                                    <p>
                                        At EveryDVM we create websites for every type of veterinary practice. It doesn't matter if you're a small animal practice, a large animal practice, 
                                        mixed or mobile - we can help. Whether you're a single-DVM practice or have multiple doctors on staff, we will build your business a website that 
                                        effectively communicates every service that you have to offer.
                                    </p>
                                    <p>
                                        With years of experience in the veterinary industry, we understand what makes each practice unique. Our websites are designed to put your best foot 
                                        forward and give your clients an easy path to find all the information they need.
                                    </p>
                                </>
                            }
                            buttonText=""
                            buttonURL=""
                            focus="text"
                            verticalOrientation="top"
                            horizontalOrientation="right"
                        />
                    </div>
                </Container>
            </Container>
            <Container fluid>
                <Container>
                    <div className="about-section-inner-container">
                        <EveryCard 
                            image={imgCaseyMcMullen} 
                            title={<div>Our History</div>} 
                            titleSeparator={true}
                            bodyText={
                                <>
                                    <p>
                                        Our founder, Casey McMullen, spent the early part of his career working on computer systems and technology in the 
                                        veterinary industry. In the late 1990s, he led a software development team to build the first veterinary 
                                        distribution e-commerce system in the United States.
                                    </p> 
                                    <p>
                                        In 2019 Casey's wife Paula, who is a territory sales rep for a veterinary distribution company, asked if he would 
                                        build some websites for a few of her clients. That idea has become EveryDVM. With his background as a software 
                                        startup entrepreneur, Casey has launched EveryDVM with the intent of creating great looking, effective websites 
                                        for veterinary practices.
                                    </p>
                                </>
                            }
                            buttonText=""
                            buttonURL=""
                            focus="text"
                            verticalOrientation="top"
                            horizontalOrientation="left"
                        />
                    </div>
                </Container>
            </Container>
            <CallToAction showSnack={showSnack} />
        </>
    );
};

export default AboutUs;