import React from "react";
import { Container } from "react-bootstrap";

import "./scss/every_hero.scss";

/**
 * EveryHero is a responsive custom hero image implementation.
 */
const EveryHero = (props) => {
    let { image, title, top = "" } = props;

    // default to a center position unless a "top" percentage value is passed in.
    let backgroundPos = "center";
    if (top) {
        backgroundPos = `center ${top}%`;
    }

    return (
        <Container fluid className="every-hero-container">
            <div className="every-hero-image-container" style={{ backgroundImage: `url(${image})`, backgroundPosition: `${backgroundPos}` }}>
                <div className="every-hero-title-container">
                    <span className="every-hero-title">{title}</span>
                </div>
            </div>
        </Container>
    );
};

export default EveryHero;