import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import "./pricing.scss";

import EveryHero from "../global/every_hero";
import PageLead from "../global/page_lead";
import CallToAction from "../global/call_to_action";

import imgHeroPricing from "../../assets/images/imgVetFamily1.jpg";

const Pricing = (props) => {
    let { showSnack } = props;
    return (
        <div className="pricing-page-container">
            <EveryHero image={imgHeroPricing} title="Pricing" top="10" />
            <PageLead
                text={<>Creating high quality products at a <span className="page-lead-text-script">fair</span> price is at the heart of everything we do.</>}
                subtext={<>Our prices are simple and straight forward and hopefully the best you'll find anywhere.</>}
            />
            <Container className="mb-5">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Website Design & Hosting</Card.Title>
                                <Card.Subtitle className="mb-3 text-muted">Basic Plan</Card.Subtitle>
                                <div className="card-text">
                                    <div className="price">$149/month</div>
                                    <div className="mb-2 text-muted price-disclaimer">Per month average when paid annually ($1,788 per year), or $165 billed monthly</div>
                                    <div className="mb-3"><strong>Requires a one-time setup fee of $1,495*</strong></div>
                                    <div className="mb-3">
                                        Includes meeting with our design consultants to discuss client deliverables (staff photos, bios, downloadable forms, etc.) and adapting your company logo and color theme to our existing template. 
                                    </div>
                                    <ul>
                                        <li>Mobile responsive</li>
                                        <li>Connects to your domain</li>
                                        <li>Ultra-fast, secure cloud hosting</li>
                                        <li>SSL certificate included</li>
                                        <li>Unlimited staff & downloadable form updates</li>
                                        <li>Google Analytics reporting</li>
                                    </ul>
                                </div>
                                <Card.Subtitle className="mt-4 mb-3 text-muted">
                                    * One-time setup fee is non-refundable and is required before website production begins
                                </Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Google Workspace Setup</Card.Title>
                                <Card.Subtitle className="mb-3 text-muted">One-Time Fee</Card.Subtitle>
                                <div className="card-text">
                                    <div className="price">$475</div>
                                    <div className="mb-2 text-muted price-disclaimer">Price covers account setup and one hour of training. Additional training is billed at $95/hour.</div>
                                    <div className="mb-3">
                                        Includes setup of Google Workspace (Gmail, Docs, Sheets, Chat, etc.) 
                                    </div>
                                    <ul>
                                        <li>Google Workspace account setup</li>
                                        <li>Connect to your domain</li>
                                        <li>Create administrator account</li>
                                        <li>Create initial Gmail accounts</li>
                                        <li>One hour of administrator training</li>
                                    </ul>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Google Workspace Administration</Card.Title>
                                <Card.Subtitle className="mb-3 text-muted">Basic Plan</Card.Subtitle>
                                <div className="card-text">
                                    <div className="price">$95/month</div>
                                    <div className="mb-2 text-muted price-disclaimer">Price covers one hour of support per month.<br />Additional hours will be billed at $95/hour.</div>
                                    <div className="mb-3">
                                        Remote administration of Google Workspace. Intended to help with adding/removing users as needed, occasional support questions, and monitor your Google billing. 
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <CallToAction showSnack={showSnack} />
        </div>
    );
};

export default Pricing;