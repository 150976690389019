import { Container } from "react-bootstrap";

import EveryCard from "../global/every_card";

import "./scss/main_value_prop.scss";

import imgMainCardAbout from "../../assets/images/imgDevDog1.jpg";

const MainValueProp = () => {
    return (
        <Container fluid className="main-value-prop-container">
            <Container>
                <div className="main-value-prop-inner-container">
                    <EveryCard 
                        image={imgMainCardAbout} 
                        title={<div>We Have a <span className="every-card-text-script">Better Option</span></div>} 
                        titleSeparator={true}
                        bodyText={
                            <ul>
                                <li>Software developers (who own pets) that create custom websites the look amazing on all screens.</li>
                                <li>Years of experience building effective websites that load fast and attract clients.</li>
                                <li>We provide same day turn around on your website requests whenever possible.</li>
                            </ul>}
                        buttonText="LEARN MORE ABOUT US"
                        buttonURL="/about-us"
                        verticalOrientation="top"
                        horizontalOrientation="left"
                    />
                </div>
            </Container>
        </Container>
    );
};

export default MainValueProp;