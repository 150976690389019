import { Container } from "react-bootstrap";

import EveryCard from "../global/every_card";

import "./scss/main_guide.scss";

import imgMainCardPromise from "../../assets/images/imgVetFamily2.webp";

const MainGuide = () => {
    return (
        <Container fluid className="main-guide-container">
            <Container>
                <div className="main-guide-inner-container">
                    <EveryCard 
                        image={imgMainCardPromise} 
                        title={<div>Our Business Is <span className="every-card-text-script">About You</span></div>} 
                        titleSeparator={true}
                        bodyText={
                            <ul>
                                <li>We create secure, mobile-friendly websites for veterinary practices.</li>
                                <li>No Wordpress templates. We handcraft custom websites with modern software technology.</li>
                                <li>Our team will take care of all of your site updates, staff and forms updates, and anything else you need so that you can focus on your business.</li>
                            </ul>}
                        buttonText="LEARN ABOUT OUR SERVICES"
                        buttonURL="/services"
                        verticalOrientation="top"
                        horizontalOrientation="right"
                    />
                </div>
            </Container>
        </Container>
    );
};

export default MainGuide;