import React, { useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./app.scss";

import ScrollToTop from "../mod/scroll_to_top";

import Header from "./header/header";
import Main from "./main/main";
import AboutUs from "./about_us/about_us";
import Services from "./services/services";
import Pricing from "./pricing/pricing";
import ContactUs from "./contact_us/contact_us";
import TermsOfUse from "./terms/terms_of_use";
import PrivacyPolicy from "./terms/privacy_policy";
import Footer from "./footer/footer";
import CookieBanner from "./cookie_banner/cookie_banner";

import SnackbarToast from "./global/snackbar_toast";

function App() {

    let snackType = useRef();
    let snackMessage = useRef();
    let [showSnackbarToast, setShowSnackbarToast] = useState(false);

    const showSnack = (type, message) => {
        snackType.current = type;
        snackMessage.current = message;
        setShowSnackbarToast(true);
    };

    const hideSnack = () => {
        setShowSnackbarToast(false);
    };

    return (
        <BrowserRouter>
            <ScrollToTop>
                <Header />
                <Routes>
                    <Route exact path='/' element={<Main showSnack={showSnack} />} />
                    <Route exact path='/about-us' element={<AboutUs showSnack={showSnack} />} />
                    <Route exact path='/services' element={<Services showSnack={showSnack} />} />
                    <Route exact path='/pricing' element={<Pricing showSnack={showSnack} />} />
                    <Route exact path='/contact-us' element={<ContactUs showSnack={showSnack} />} />
                    <Route exact path='/terms' element={<TermsOfUse />} />
                    <Route exact path='/privacy' element={<PrivacyPolicy />} />
                </Routes>
                { showSnackbarToast && 
                    <SnackbarToast 
                        toast={snackType.current} 
                        message={snackMessage.current}
                        onClose={hideSnack}
                    />
                }
                <Footer />
                <CookieBanner />
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;